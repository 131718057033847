import React from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import Header_trans from "../../component/HeaderTransparent";
import HomeContain from "./HomeContain";

const Home = ()=>{
    return (
        <>
        <Header />
        <HomeContain />
        <Footer />
        </>
    )
}

export default Home;