export const ALERTS = "ALERTS";
export const SET_ALERTS = "SET_ALERTS";

export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_REQUEST";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const USER_TOKEN = "USER_TOKEN";

export const CATEGORIES = "CATEGORIES";
export const URLS = "URLS";
export const BRANDS = "BRANDS";
export const SITE_SETTINGS = "SITE_SETTINGS";

export const ADD_TO_CART = "ADD_TO_CART";
export const CART_CLEAR = "CART_CLEAR";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const INCREMENT_QUANTITY = "INCREMENT_QUANTITY";
export const DECREMENT_QUANTITY = "DECREMENT_QUANTITY";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const USER_ADDRESS = "USER_ADDRESS";

export const GALLERY_IMG = "GALLERY_IMG";
export const DONATE_CAMPAIGN = "DONATE_CAMPAIGN";

export const UPDATE_ADD_TO_CART = "UPDATE_ADD_TO_CART";

export const DELIVERY_DATES = "DELIVERY_DATES";
export const SOCIAL_LINKS = "SOCIAL_LINKS";

export const STEP_DATA = "STEP_DATA";

